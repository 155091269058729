import React from 'react';
import { SubscribeBoxStyles } from '../../styles/Components/BlogContent/BlogContentStyles';

const SubscribeBox = () => (
  <SubscribeBoxStyles>
    <h5>Solid advice delivered to your inbox.</h5>
    <p>Get free online marketing tips and resources.</p>
    <button type="submit" className="btn btn-primary w-100">
      Subscribe
    </button>
  </SubscribeBoxStyles>
);

export default SubscribeBox;
